import React from 'react';
import * as Ink from '@carta/ink';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const TableComponents = ({
  data: {
    tableComponents: { nodes },
  },
}) => {
  return (
    <Layout pageTitle="Table Components">
      <Header title="Table" />

      <Ink.Tiles columns={[1, 1, 2, 2, 3]}>
        {nodes
          .filter(cmp => (cmp.group ? cmp.group === cmp.displayName : true))
          .map(component => (
            <PreviewCard
              title={component.displayName}
              text={component.purpose}
              actionLabel="View details"
              actionHref={`/components/${component.displayName}`}
              previewWithCheckerboard
              previewCode={component.samples && component.samples.length > 0 && component.samples[0].code}
            />
          ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default TableComponents;

export const query = graphql`
  query TableComponentsQuery {
    tableComponents: allComponentsJson(filter: { categories: { in: "table" } }, sort: { fields: position }) {
      nodes {
        ...ComponentFields
      }
    }
  }
`;
